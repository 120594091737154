import React, { useContext, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { AuthProvider, AuthContext } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import FloatingLogo from './Components/FloatingLogo';
// import Base from './Base';
// import Signin from './Pages/SigninPage';
// import Signup from './Pages/SignupPage';
// import TwoFactor from './Pages/TwoFactor';
// import ShareFile from './Pages/ShareFile';


const Base = lazy(() => import('./Base'));
const Signin = lazy(() => import('./Pages/SigninPage'));
const Signup = lazy(() => import('./Pages/SignupPage'));
const TwoFactor = lazy(() => import('./Pages/TwoFactor'));
const ShareFile = lazy(() => import('./Pages/ShareFile'));



function App() {
  const { isCheckingAuth } = useContext(AuthContext);

  if (isCheckingAuth) {
    return <FloatingLogo />;
  }

  return (
    <Router>
      <Suspense fallback={<FloatingLogo />}>
        <Routes>
          <Route path="/login" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="2fa" element={<TwoFactor />} />
          <Route path="/share/:id" element={<ShareFile />} />
          <Route path="/*" element={<PrivateRoute><Base /></PrivateRoute>} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;